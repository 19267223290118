.App {
    text-align: center;
  }
  
  .Horizontal-Bar {
    display: 'flex';
    flex-direction: 'column';
  }
  
  .Horizontal-Bar a button {
    border-radius: 0;
  }

  .Horizontal-Bar a button:hover {
    background-color: #5c8cf3;
  }
  
  .Horizontal-Bar a.active button {
    color: white;
    background-color: #1976d2;
  }

  .fc .fc-timegrid-slot {
    height: 4em; /* Set your desired pixel height */
  }
